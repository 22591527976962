"use strict";

/**
 * UI functions
 */
function updateCookieToggles() {
  let user_consented = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  if (orGTM.or_gtm_consentSet()) {
    jQuery('.js--cookies__toggle_functionality_storage').toggleClass('js--active', orGTM.or_gtm_getConsent('functionality_storage'));
    jQuery('.js--cookies__toggle_analytics_storage').toggleClass('js--active', orGTM.or_gtm_getConsent('analytics_storage'));
    jQuery('.js--cookies__toggle_ad_storage').toggleClass('js--active', orGTM.or_gtm_getConsent('ad_storage'));
  } else {
    jQuery('.js--cookies__toggle_functionality_storage').toggleClass('js--active', true);
  }

  if (user_consented) {
    orGTM.or_gtm_set_user_consent();
  }
}
/**
 * Handle UI
 */


jQuery(function () {
  // Accordeon
  jQuery('.accordeon').on('click', '.js--accordeon_item_toggle', function (e) {
    e.preventDefault();
    jQuery(this).parents('.accordeon__item').toggleClass('js--unfolded');
    jQuery(this).parents('.accordeon__item').find('.icon-unfold').toggleClass('js--unfolded');
  }); // end: accordeon
  // Step handling

  jQuery('.modal.cookies').on('click', '[data-cookies-goto]', function (e) {
    e.preventDefault();
    var goto = jQuery(this).data('cookies-goto');
    jQuery('.modal.cookies').find('.cookies__step').removeClass('js--active');
    jQuery('.modal.cookies').find('.cookies__step[data-cookies-step="' + goto + '"]').addClass('js--active');
  });
  jQuery('.modal.cookies').on('click', '.js--cookies-back', function (e) {
    e.preventDefault();
    var step = jQuery(this).parents('[data-cookies-step]').data('cookies-step');
    var goto = step - 1;
    jQuery('.modal.cookies').find('.cookies__step').removeClass('js--active');
    jQuery('.modal.cookies').find('.cookies__step[data-cookies-step="' + goto + '"]').addClass('js--active');
  }); // Cookies modal handling

  if (orGTM.or_gtm_consentSet() && orGTM.or_gtm_user_consented()) {
    updateCookieToggles();
  } else {
    orGTM.or_gtm_initConsent(orGTM.or_gtm_getDefault());
    updateCookieToggles();
    jQuery('#cookies-modal').fadeIn(200);
  }

  jQuery('.js--cookies__all').on('click', function (e) {
    e.preventDefault();
    orGTM.or_gtm_setConsent('functionality_storage', 'granted');
    orGTM.or_gtm_setConsent('analytics_storage', 'granted');
    orGTM.or_gtm_setConsent('ad_storage', 'granted');
    orGTM.or_gtm_setConsent('ad_user_data', 'granted');
    orGTM.or_gtm_setConsent('ad_personalization', 'granted');
    updateCookieToggles(true);
    jQuery('#cookies-modal').fadeOut(200);
  });
  jQuery('.js--cookies-close').on('click', function (e) {
    e.preventDefault();
    updateCookieToggles(true);
    jQuery('#cookies-modal').fadeOut(200);
  });
  jQuery('.js--cookies__functionality_storage').on('click', function (e) {
    e.preventDefault();
    orGTM.or_gtm_setConsent('functionality_storage', 'granted');
    updateCookieToggles(true);
    jQuery('#cookies-modal').fadeOut(200);
  });
  jQuery('.js--cookies__analytics_storage').on('click', function (e) {
    e.preventDefault();
    orGTM.or_gtm_setConsent('analytics_storage', 'granted');
    updateCookieToggles(true);
    jQuery('#cookies-modal').fadeOut(200);
  });
  jQuery('.js--cookies__ad_storage').on('click', function (e) {
    e.preventDefault();
    orGTM.or_gtm_setConsent('ad_storage', 'granted');
    orGTM.or_gtm_setConsent('ad_personalization', 'granted');
    orGTM.or_gtm_setConsent('ad_user_data', 'granted');
    updateCookieToggles(true);
    jQuery('#cookies-modal').fadeOut(200);
  });
  /** Cookie settings **/

  jQuery('.js--cookies__toggle_functionality_storage').on('click', function () {
    //orGTM.or_gtm_toggleConsent('functionality_storage');
    // Functional cookies are enabled by default
    updateCookieToggles();
  });
  jQuery('.js--cookies__toggle_analytics_storage').on('click', function () {
    orGTM.or_gtm_toggleConsent('analytics_storage');
    updateCookieToggles(true);
  });
  jQuery('.js--cookies__toggle_ad_storage').on('click', function () {
    orGTM.or_gtm_toggleConsent('ad_storage');
    orGTM.or_gtm_toggleConsent('ad_personalization');
    orGTM.or_gtm_toggleConsent('ad_user_data');
    updateCookieToggles(true);
  });
  /** end: cookie settings **/
  // end: cookies modal handling
});